html, body {
    margin: 0;
    min-height: 800px;
    height: 100%;
}

/* resize modal dialog width */
.modal-dialog {
    max-width: 700px;
}

.remove-filter-button {
    margin: 1px;
    cursor: pointer;
}

.menuTabs {
    margin-bottom: 0 !important;
}