.modal-dropdown {
    left: 50%;
    margin-left: -200px;
    top: 50%;
    position: absolute;
    background-color: brown;
}

.imageContainer {
    width: 100px;
    height: 100px;
}

.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    display: block;
    margin: auto;
}

.formHeader {
    min-width: 70px;
}

input[type="number"] {
    max-width: 100px;
}

.img-box {
    overflow: hidden;
    margin: auto;
    text-align: center;
}

.img-box img {
    max-width: 200px;
    max-height: 160px;
}

.option-badge {
    font-weight: 400;
}

.back-white {
    background-color: white;
}