/* .caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
} */

    /* .caption small {
        display: table-cell;
        vertical-align: middle;
        color: white;
    } */

.caption {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 25%;
    overflow: hidden;
    display: none;
    background-color: rgba(0,0,0,0.7);
    line-height: 1.15;
}    

    .caption small {
        color: white;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .productSpace .btn {
        display: none;
        height: 40px;
    }

.boxOverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.middleBox {
    height:50%;
    max-height: 50%;
    display: flex;
    align-items: center;
}

.thumbnail {
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1.11667px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1.11667px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1.11667px;
    border-top-color: rgb(221, 221, 221);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1.11667px;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 2px 0px;
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    display: block;
    font-family: "Open Sans", sans-serif, Arial;
    font-size: 16px;
    font-weight: 400;
    height: 150px;
    line-height: 22.85px;
    margin-bottom: 20px;
    min-height: 150px;
    overflow: hidden;
    overflow-wrap: break-word;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: border;
    transition-timing-function: ease-in-out
}

.productSpace {
    padding-left: 5px;
    padding-right: 5px;
    min-height: 150px;
    height: 150px;
    margin-bottom:10px;
}

.productSpace:hover .caption {
    display: block;
}

.productSpace:hover .btn {
    margin: 0 auto;
    display: block;
}

.productSpace.dragging .dropProductImage {
    display: table;
}

.productSpace.dragging .caption {
    display: table;
}

.label-over {
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgb(230, 230, 230);
    opacity: 0.9;
    z-index: 1000;
}

.label-over-titulo {
    transform: rotate(-90deg);
    width: 100%;
    height: 40px;
    text-align: center;
    min-width: 130px;
}

.label-over-titulo h4 {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: normal;
    text-align: center;
    font-size: .8em;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonOver {
    position: absolute;
    top: 105px;
    right: 5px;
}