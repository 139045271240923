/* .btn-filter-grid {
    padding: 10px;
    margin-left: 10px;
    max-height: 25px;
} */

.clickable-badge {
    cursor: pointer;
    font-size: small;
}

.category-filter {
    margin-left: 5px;
}